import { Button } from '@mantine/core';
import styles from './eSim.module.scss';
import cn from 'classnames';
import { HTMLAttributes } from 'react';
import Link from 'next/link';
import { LINKS } from '@/shared/constants/links';
import { useRouter } from 'next/router';

interface EsimProps extends HTMLAttributes<HTMLDivElement> {}

export function Esim({ className }: EsimProps) {
  const router = useRouter();
  const toLink = () => {
    router.push(LINKS.ESIM);
  };

  return (
    <div className={cn(styles.card, className && className)} onClick={toLink}>
      <div className={styles.headerInfo}>
        <span className={styles.header}>eSIM</span>
        <span className={styles.info}>
          Обычная SIMка,
          <br />
          только без пластика
        </span>
      </div>
      <Link href={LINKS.ESIM}>
        <Button className={cn('esim-btn', styles.button__root)}>Оформить eSIM</Button>
      </Link>
    </div>
  );
}
