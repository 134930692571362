import { shuffle, slice, concat } from 'lodash';
import { isAfter } from 'date-fns';
import { useGetBeautifulNumbersQuery } from '@/store/api/beautiful-numbers/beautiful-numbers.api';
import { ORDER_PARAMS } from '@/modules/tariff/shared/requests/constants';
import { blackFridayDeadline } from '@/shared/constants/black-friday';

const defaultPrices = [
  [500, 1000],
  [1000, 2500],
  [2500, 5000],
  [5000, 10000],
  [10000, 30000],
  [30000, 50000],
  [50000, 200000],
  [200000, 999999999],
];
const blackFridayPrices = [
  [90, 290],
  [290, 790],
  [790, 1900],
  [1900, 4900],
  [4900, 17900],
  [17900, 32900],
  [32900, 149000],
  [149000, 529000],
];

export const useBeautifulNumbers = () => {
  const isPromotionExpired = isAfter(new Date(), blackFridayDeadline);
  const prices = isPromotionExpired ? defaultPrices : blackFridayPrices;

  const { data: firstGroupBeautifulNumbers, isLoading: isLoading1 } = useGetBeautifulNumbersQuery({
    limit: 5,
    last_id: 0,
    price_from: prices[0][0],
    price_to: prices[0][1],
    random_list: true,
    source_id: ORDER_PARAMS.SOURCE_ID,
  });
  const { data: secondGroupBeautifulNumbers, isLoading: isLoading2 } = useGetBeautifulNumbersQuery({
    limit: 7,
    last_id: 0,
    price_from: prices[1][0],
    price_to: prices[1][1],
    random_list: true,
    source_id: ORDER_PARAMS.SOURCE_ID,
  });
  const { data: thirdGroupBeautifulNumbers, isLoading: isLoading3 } = useGetBeautifulNumbersQuery({
    limit: 12,
    last_id: 0,
    price_from: prices[2][0],
    price_to: prices[2][1],
    random_list: true,
    source_id: ORDER_PARAMS.SOURCE_ID,
  });
  const { data: fourthGroupBeautifulNumbers, isLoading: isLoading4 } = useGetBeautifulNumbersQuery({
    limit: 5,
    last_id: 0,
    price_from: prices[3][0],
    price_to: prices[3][1],
    random_list: true,
    source_id: ORDER_PARAMS.SOURCE_ID,
  });
  const { data: fifthGroupBeautifulNumbers, isLoading: isLoading5 } = useGetBeautifulNumbersQuery({
    limit: 12,
    last_id: 0,
    price_from: prices[4][0],
    price_to: prices[4][1],
    random_list: true,
    source_id: ORDER_PARAMS.SOURCE_ID,
  });
  const { data: sixthGroupBeautifulNumbers, isLoading: isLoading6 } = useGetBeautifulNumbersQuery({
    limit: 5,
    last_id: 0,
    price_from: prices[5][0],
    price_to: prices[5][1],
    random_list: true,
    source_id: ORDER_PARAMS.SOURCE_ID,
  });
  const { data: seventhGroupBeautifulNumbers, isLoading: isLoading7 } = useGetBeautifulNumbersQuery({
    limit: 2,
    last_id: 0,
    price_from: prices[6][0],
    price_to: prices[6][1],
    random_list: true,
    source_id: ORDER_PARAMS.SOURCE_ID,
  });
  const { data: eighthGroupBeautifulNumbers, isLoading: isLoading8 } = useGetBeautifulNumbersQuery({
    limit: 1,
    last_id: 0,
    price_from: prices[7][0],
    price_to: prices[7][1],
    random_list: true,
    source_id: ORDER_PARAMS.SOURCE_ID,
  });

  const numbers = [
    firstGroupBeautifulNumbers,
    secondGroupBeautifulNumbers,
    thirdGroupBeautifulNumbers,
    fourthGroupBeautifulNumbers,
    fifthGroupBeautifulNumbers,
    sixthGroupBeautifulNumbers,
    seventhGroupBeautifulNumbers,
    eighthGroupBeautifulNumbers,
  ]
    .filter(Boolean)
    ?.flatMap((result) => Object.values((result && result) || {}).flat());

  const firstSix = slice(numbers, 0, 6);
  // Перемешиваем все элементы после 6-го, чтобы не было морганий при загрузке номеров
  const rest = shuffle(slice(numbers, 6));
  const shuffledNumbers = concat(firstSix, rest);
  const isLoading = isLoading1 || isLoading2 || isLoading3 || isLoading4|| isLoading5 || isLoading6 || isLoading7 || isLoading8;

  return { numbers: shuffledNumbers, isLoading: isLoading };
};
