import { FC, useEffect, useRef, useState } from 'react';
import TariffCard from '@/shared/ui-kit/tariff-card/tariff-card';
import { useMediaQuery } from '@/shared/lib/use-media-query';
import {
  homeInternetCategoryResponseMapper,
  mobileTariffResponseMapper,
} from '@/modules/tariff/shared/helpers';
import { MOYA_VOLNA, MOYA_VOLNA_PROMOTION_OPTIONS } from '@/modules/tariff/shared/constants';
import { TariffMobileEntity } from '@/store/api/tariff-mobile/tariff-mobile.type';
import { Swiper, SwiperSlide, SwiperClass } from 'swiper/react';
import { Navigation, Pagination, A11y } from 'swiper/modules';
import { TABS, TABS_LABEL } from './constants';
import { Pills } from '@/shared/ui-kit';
import { carouselMessages } from '../carousel/constants';
import { TariffHomeInternetCategoryEntity } from '@/store/api/tariff-home-internet-category/tariff-home-internet-catogory.type';
import { DIY_TARIFF_NAME, EVPATORIA_NAME } from '@/shared/constants/diy';
import cn from 'classnames';
import styles from './tariffs-carousel.module.scss';

interface TariffsCarouselProps {
  tariffsMobile: TariffMobileEntity;
  tariffHomeInternetCategories: TariffHomeInternetCategoryEntity;
}

export const TariffsCarousel: FC<TariffsCarouselProps> = ({ tariffsMobile, tariffHomeInternetCategories }) => {
  const [activeTab, setActiveTab] = useState(TABS.MOBILE);
  const { isMobile, isDesktop } = useMediaQuery();
  const swiperRef = useRef<SwiperClass>();

  const tariffsMobileData = tariffsMobile ? mobileTariffResponseMapper(tariffsMobile) : [];
  const homeInternetCategoriesData = tariffHomeInternetCategories
    ? homeInternetCategoryResponseMapper(tariffHomeInternetCategories)
    : [];

  useEffect(() => {
    if (swiperRef.current) {
      swiperRef.current.slideTo(0);
    }
  }, [activeTab]);

  const data = [
    {
      value: TABS.MOBILE,
      label: TABS_LABEL[TABS.MOBILE],
    },
    {
      value: TABS.INTERNET,
      label: TABS_LABEL[TABS.INTERNET],
    },
  ];

  return (
    <>
      <div className={styles.header}>
        <h3 className={styles.title}>Тарифы</h3>
        <Pills className={cn('pills-tariff')} data={data} value={activeTab} onChange={(val: TABS) => setActiveTab(val)} />
      </div>
      <Swiper
        modules={[Navigation, Pagination, A11y]}
        navigation={!!isDesktop}
        pagination={{ clickable: true }}
        className={cn(styles.swiper, activeTab === TABS.INTERNET && styles.swiper__homeTab)}
        slidesPerView={1}
        spaceBetween={20}
        centeredSlides={!!isMobile}
        centeredSlidesBounds={!!isMobile}
        a11y={carouselMessages}
        onSwiper={(swiper) => {
          swiperRef.current = swiper;
        }}
        breakpoints={{
          265: {
            slidesPerView: 'auto',
            spaceBetween: 0,
          },
          768: {
            slidesPerView: 'auto',
            spaceBetween: 0,
          },
          1440: {
            slidesPerView: 4,
            spaceBetween: 20,
          },
        }}
      >
        {activeTab === TABS.MOBILE ?
          tariffsMobileData.map(({ offer_file, ...rest }) => (
            <SwiperSlide key={rest.id}>
              <TariffCard
                activeTab={activeTab}
                {...rest}
                /** костыль для суперсилы */
                optionsMobile={rest.name === MOYA_VOLNA.NAME ? MOYA_VOLNA_PROMOTION_OPTIONS : rest.optionsMobile} 
                priceFrom={rest.price_from}
                promotion={{ promotion_price: rest.promotion_price }}
              />
            </SwiperSlide>
          )) :
          homeInternetCategoriesData?.map(({ offer_file, ...rest }) => (
            <SwiperSlide key={rest.id}>
              <TariffCard
                activeTab={activeTab}
                {...rest}
                name={rest.category_name}
                localized_name={rest.category_localized_name}
                optionsMobile={rest.optionsMobile.map((item) => ({ ...item, from: true }))}
                optionsHomeInternet={rest.optionsHomeInternet
                  .filter((item) => item.icon !== 'router' && item.icon !== 'connection')
                  .map((item) => ({
                    ...item,
                    from: true,
                  }))}
                price={rest.counted_prices.default_price}
                priceFrom={
                  rest.category_name === DIY_TARIFF_NAME
                    ? rest.counted_prices.promotion?.promotion_price
                    : rest.category_name === EVPATORIA_NAME
                      ? rest.counted_prices.default_price
                      : undefined
                }
                promotion={rest.counted_prices.promotion}
                promotionPriceFrom
              />
            </SwiperSlide>
          ))}
      </Swiper>
    </>
  );
};
