import { ArrowRight } from '@/shared/assets/svg';
import styles from './news-header.module.scss';
import { Pills } from '@/shared/ui-kit';
import Link from 'next/link';
import { Path, PathLabels } from '@/shared/constants/links';
import cn from 'classnames';

interface NewsHeaderProps {
  onChangeTag: (value: string) => void;
  tags: string[];
  activeTag: string;
}

export const NewsHeader: React.FC<NewsHeaderProps> = ({ onChangeTag, tags, activeTag }) => (
  <div className={cn('pills-news', styles.wrapper)}>
    <Link className={styles.link} href={Path.news}>
      <span>{PathLabels.news}</span>
      <ArrowRight />
    </Link>
    <Pills data={tags} value={activeTag} onChange={onChangeTag} />
  </div>
);
