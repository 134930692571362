import { useMemo, useState } from 'react';
import Link from 'next/link';
import { LoadingOverlay } from '@mantine/core';
import { Swiper, SwiperSlide } from 'swiper/react';
import { Navigation, Pagination, A11y } from 'swiper/modules';
import { format } from 'date-fns';
import { useGetArticlesQuery } from '@/store/api/article/article.api';
import { useMediaQuery } from '@/shared/lib/use-media-query';
import { ChevronRight } from '@/shared/assets/svg';
import { Path } from '@/shared/constants/links';
import { PILLS } from '@/modules/news-list/constants';
import { NewsCard } from '@/shared/ui-kit/news-card/news-card';
import { COLORS } from '@/shared/constants/colors';
import { ArticleEntity } from '@/store/api/article/article.type';
import { NewsHeader } from './news-header/news-header';
import { articlesResponseToArticleCarouselMapper } from './news.mapper';
import styles from './news.module.scss';
import React from 'react';

interface NewsProps {
  articles?: ArticleEntity | null;
}

function NewsComponent({ articles }: NewsProps) {
  const [activeFilter, setActiveFilter] = useState<string>(Object.values(PILLS)[0]);
  const { isMobile } = useMediaQuery();
  const tags = Object.values(PILLS);
  const now = format(new Date(Date.now()), 'yyyy-MM-dd HH:mm:ss');

  const {
    data: dataNews,
    isLoading,
    isFetching,
    isError,
  } = useGetArticlesQuery({
    populate: '*',
    ['filters[publishDate][$lte]']: now,
    'pagination[page]': 1,
    'pagination[pageSize]': 7,
    ...(activeFilter !== tags[0] ? { ['filters[tag][$eq]']: activeFilter } : {}),
    sort: 'publishDate:desc',
  });

  const currentData = useMemo(() => dataNews || articles, [dataNews, articles]);

  const onChangeTag = (value: string) => {
    setActiveFilter(value);
  };

  if (isLoading || isFetching) {
    return (
      <div className={styles.loading}>
        {!isMobile && <NewsHeader onChangeTag={onChangeTag} tags={tags} activeTag={activeFilter} />}
        <LoadingOverlay visible loaderProps={{ color: COLORS.volna.magenta[100] }} />
      </div>
    );
  }

  if (isError) {
    return null;
  }

  const articlesData = currentData?.data ? articlesResponseToArticleCarouselMapper(currentData) : [];

  return (
    <div className={styles.wrapper}>
      {!isMobile && <NewsHeader onChangeTag={onChangeTag} tags={tags} activeTag={activeFilter} />}
      <Swiper
        modules={[Navigation, Pagination, A11y]}
        navigation={!isMobile}
        pagination={{ clickable: true }}
        className={styles.swiper}
        slidesPerView={'auto'}
        spaceBetween={20}
        centeredSlides={!!isMobile}
        centeredSlidesBounds={!!isMobile}
      >
        {articlesData.map((item) => {
          return (
            <SwiperSlide key={item.id}>
              <Link href={`${Path.news}/${item.id}`}>
                <NewsCard
                  image={item.src}
                  title={item.title}
                  date={item.date}
                  id={0}
                  tag={item.tag}
                  alternativeText={item.alternativeText}
                  mediaFormat={item.mediaFormat}
                />
              </Link>
            </SwiperSlide>
          );
        })}
        {Number(currentData?.meta?.pagination.pageCount) > 0 && (
          <SwiperSlide>
            <Link href={Path.news}>
              <div className={styles.more}>
                <div className={styles.more__wrapper}>
                  <div className={styles.more__icon}>
                    <ChevronRight />
                  </div>
                  <div className={styles.more__text}>Больше новостей и акций</div>
                </div>
              </div>
            </Link>
          </SwiperSlide>
        )}
      </Swiper>
    </div>
  );
}

export const News = React.memo(NewsComponent);
